import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Link from "next/link";
import Image from "next/image";


import carouselBGimg1 from "../../assets/images/home/Banner/UPSC-CSE-2025-Webbanner.jpg";
import carouselBGimg2 from "../../assets/images/home/Banner/UPSC-Result-2023-Web-Banner.webp";
import carouselBGimg3 from "../../assets/images/home/Banner/Prefit-and-Prestorming-Web-Banner-Button.jpg";
import carouselBGimg4 from "../../assets/images/home/Banner/UPSC-Moct-test-Web-Button.jpg";
import carouselBGimg5 from "../../assets/images/home/Banner/UPSC-Your-Dream-Web-Banner.jpg";
import carouselBGimg6 from "../../assets/images/home/Banner/TNPSC-GR-I-Result-2022_Webbanner.webp";
import carouselBGimg7 from "../../assets/images/home/Banner/TNPSC-Prelims-coaching-Web-banner-button.jpg";


{/* mobile */}

import carouselMBimg1 from "../../assets/images/home/Mob_Banner/UPSC-CSE-2025-Mobbanner.webp";
import carouselMBimg2 from "../../assets/images/home/Mob_Banner/UPSC-Result-2023-Moblie-Banner.webp";
import carouselMBimg3 from "../../assets/images/home/Mob_Banner/Prefit-and-Prestorming-Mobile-Banner-Button.jpg";
import carouselMBimg4 from "../../assets/images/home/Mob_Banner/UPSC-Mock-test-Mob-Button.jpg";
import carouselMBimg5 from "../../assets/images/home/Mob_Banner/UPSC-Your-Dream-Mobile-Banner.jpg";
import carouselMBimg6 from "../../assets/images/home/Mob_Banner/TNPSC-GR-I-Result-2022-Mobilebanner.webp";
import carouselMBimg7 from "../../assets/images/home/Mob_Banner/TNPSC-Prelims-coaching-Mob-banner-button.jpg";

function slider() {

    const BGimg = [

      { quickLoading: false, img: carouselBGimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      { quickLoading: false, img: carouselBGimg4, alt: "carousel", link: "/all-india-upsc-prelims-mock-test" },
      { quickLoading: false, img: carouselBGimg7, alt: "carousel", link: "/tnpsc-admissions" },
      { quickLoading: false, img: carouselBGimg3, alt: "carousel", link: "/upsc-prelims-test-series" },
      { quickLoading: false, img: carouselBGimg1, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      { quickLoading: false, img: carouselBGimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      { quickLoading: false, img: carouselBGimg6, alt: "carousel", link: "/tnpsc-admissions" }
      
       ]

      const MBimg = [
        { quickLoading: false, img: carouselMBimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg4, alt: "carousel", link: "/all-india-upsc-prelims-mock-test" },
        { quickLoading: false, img: carouselMBimg7, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselMBimg3, alt: "carousel", link: "/upsc-prelims-test-series" },
        { quickLoading: false, img: carouselMBimg1, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg6, alt: "carousel", link: "/tnpsc-admissions" }
        
      
        
      ]
  
  return (
    <>
        {/* Carousel large devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-none d-md-block">
            <Carousel>
              {BGimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid w-100 h-100"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

      {/* Carousel Mobile devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-block d-md-none">
            <Carousel>
              {MBimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

    </>
  )
}

export default slider